// const instance = axios.create({
//     baseURL: 'https://localhost:8000/',
//     timeout: 1000,
//     headers: {'X-Custom-Header': 'foobar'}
//   });
//   export default instance;

// const BaseURL = "http://64.227.130.181:9002/"
// const BaseURL = "http://django.febinosolutions.com/"
// const BaseURL = "http://febinosolutions.com:9002/"
const BaseURL = "https://ifmapi.univa.cloud/"
// const BaseURL = "http://localhost:9002/"
export default BaseURL